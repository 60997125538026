<template>
  <el-card class="box-card">
    <div class="row">
      <div class="col-md-6">
        <h3 class="bold">Thông tin học viên</h3>
        <div class="row">
          <div class="col-md-6">
            Họ và tên:
          </div>
          <div class="col-md-6 bold" v-if="customer.user">{{ customer.user.name }}</div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            Số điện thoại:
          </div>
          <div class="col-md-6 bold" v-if="customer.user">{{ customer.user.phone }}</div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            Test đầu vào:
          </div>
          <div class="col-md-6 bold" v-if="customer.user">{{customer.diem_test}}</div>
        </div>
        <div class="row"></div>
        <div class="row"></div>
      </div>
      <div class="col-md-6">
        <h3 class="bold">Người giới thiệu</h3>
        <div class="row">
          <div class="col-md-6">
            Họ và tên:
          </div>
          <div class="col-md-6 bold">{{ customer.user_intro_name }}</div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            Số điện thoại:
          </div>
          <div class="col-md-6 bold">{{ customer.user_intro_phone }}</div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            Văn phòng:
          </div>
          <div class="col-md-6 bold">{{ customer.user_intro_branch }}</div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "CustomerInfo",
  props: ['customer']
}
</script>

<style>

</style>