<template>
  <div class="row">
    <div class="col-xl-12">
      <CustomerInfo v-if="customer.id" :customer="customer"></CustomerInfo>
      <div id="panel-1" class="panel mt-2">
        <div class="panel-container show">
          <div class="panel-content">
            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                <el-select filterable @change="changeProvince()" class="w-100 mt-3" clearable
                           v-model="filter.province_id"
                           placeholder="Chọn Tỉnh/TP">
                  <el-option
                      v-for="item in provinces"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                <el-select filterable @change="changeDistrict()" class="w-100 mt-3" clearable
                           v-model="filter.district_id"
                           placeholder="Quận/Huyện">
                  <el-option
                      v-for="item in districts"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                <el-select filterable @change="changeCenter()" class="w-100 mt-3" clearable v-model="filter.center_id"
                           placeholder="Trung tâm">
                  <el-option
                      v-for="item in centers"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="24" :md="8" :lg="3" :xl="2">
                <button :disabled="loading" class="btn btn-primary w-100 mt-3" @click="submit()"><i
                    v-bind:class="[loading ? 'el-icon-loading' : 'el-icon-search']"></i> Tìm kiếm
                </button>
              </el-col>
            </el-row>
            <el-row class="row" style="margin-top: 15px" v-if="this.is_submit" :gutter="20">
              <el-col v-for="(item, index) in this.listCenter" :key="index" class="col-sm-12 col-md-6 col-lg-3">
                <CenterItem
                    v-if="!item.combo"
                    :center="item"
                    :is_sale_view="true"
                >
                </CenterItem>
              </el-col>
              <el-col class="text-center" v-if="this.page < this.last_page" :xs="24" :sm="24" :md="24" :lg="24"
                      :xl="24">
                <a href="javascript:void(0)" @click="addPage()">Xem thêm</a>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {TU_VAN_CENTER_LIST, TU_VAN_INDEX} from "@/core/services/store/tuVan.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import CenterItem from './CenterItem';
import {mapGetters} from "vuex";
import {GET_PROVINCES} from "../../../../core/services/store/public/provinces.module";
import {GET_DISTRICTS} from "../../../../core/services/store/public/districts.module";
import CustomerInfo from "./component-detail-center/CustomerInfo";
import {CUSTOMER_FIND} from "../../../../core/services/store/customer/customer.module";

export default {
  name: "AdviseCenter",
  components: {
    CustomerInfo,
    CenterItem
  },
  data() {
    return {
      loading: false,
      get_center_default: true,
      provinces: [],
      districts: [],
      centers: [],
      branches: [],
      course_levels: [],
      customer: {},
      types: [
        {
          id: 1,
          name: 'Học thật'
        },
        {
          id: 2,
          name: 'Học thử'
        },
      ],
      listCenter: [],
      center_total: 0,
      isRemainng: 0,
      is_submit: false,
      last_page: 0,
      page: 1,

      filter: {
        province_id: '',
        district_id: '',
        center_id: '',
        customer_id: ''
      }
    }
  },
  async mounted() {
    this.filter.customer_id = this.$route.query.customer_id;
    this.callApi();
    await this.getProvinces();
    await this.getDistricts();
    this.getCenters();
    this.getCustomer();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Tìm kiếm trung tâm", icon: ''}
    ]);
  },
  methods: {
    getCustomer() {
      let customer_id = this.$route.query.customer_id;
      if (!customer_id) return;
      this.$store.dispatch(CUSTOMER_FIND, customer_id).then((res) => {
        if (!res.error) {
          this.customer = res.data;
        }
      }).catch((e) => {
        console.log(e);
      });
    },
    getProvinces() {
      this.$store.dispatch(GET_PROVINCES, {
        province_ids: [1, 2, 55, 61]
      }).then((res) => {
        if (!res.error) {
          this.provinces = res.data;
        }
      }).catch((e) => {
        console.log(e);
      });
    },
    getDistricts() {
      this.$store.dispatch(GET_DISTRICTS, {
        province_id: this.filter.province_id
      }).then((res) => {
        if (!res.error) {
          this.districts = res.data
        }
      }).catch((e) => {
        console.log(e);
      });
    },

    getCenters() {
      this.$store.dispatch(TU_VAN_CENTER_LIST, {
        province_id: this.filter.province_id,
        district_id: this.filter.district_id,
        status: 1,
      }).then((response) => {
        if (!response.error) {
          this.centers = response.data;
          this.loading = false;
        }
      });
    },

    changeProvince() {
      this.filter.district_id = '';
      this.filter.center_id = '';
      this.getDistricts();
      this.changeDistrict();
    },

    changeDistrict() {
      this.filter.center_id = '';
      this.getCenters();
      this.changeCenter();
    },

    changeCenter() {
      let type = 'center';
      this.filter.customer_id = '';
    },

    addPage() {
      this.page = this.page + 1;
      this.loadMore();
    },
    submit() {
      if (this.filter.province_id == '' && this.filter.district_id == '' && this.filter.center_id == '') {
        this.$notify({
          title: "Warning",
          message: `Vui lòng chọn một trong các bộ lọc vị trí`,
          type: 'warning'
        })
        return;
      }
      this.loading = true;
      this.callApi();
    },
    callApi() {
      this.$store.dispatch(TU_VAN_INDEX, {
        province_id: this.filter.province_id,
        district_id: this.filter.district_id,
        center_id: this.filter.center_id,
        customer_id: this.filter.customer_id,
        payment: this.$route.query.payment
      }).then((response) => {
        if (!response.error) {
          this.is_submit = true;
          this.listCenter = response.data.data;
          this.last_page = response.data.last_page;
          this.center_total = response.data.total;
          this.isRemainng = response.data.isRemainng;
          if (this.get_center_default) {
            this.listCenter.map((value) => {
              this.filter.center_id = value.id;
            })
            this.get_center_default = false;
          }
        }
        this.loading = false;
      })
    },
    loadMore() {
      this.$store.dispatch(TU_VAN_INDEX, {
        province_id: this.filter.province_id,
        district_id: this.filter.district_id,
        center_id: this.filter.center_id,
        customer_id: this.$route.query.customer_id,
        payment: this.$route.query.payment,
        page: this.page
      }).then((response) => {
        if (!response.error) {
          this.last_page = response.data.last_page;
          this.listCenter.push(...response.data.data);
          this.center_total = response.data.total;
          this.isRemainng = response.data.isRemainng;
        }
        this.loading = false;
      })
    },
  },
  computed: {
    ...
        mapGetters(['currentUser'])
  }
}
</script>

<style scoped>

</style>