<template>
  <div class="edu-card combo">
    <div class="edu-card-header">
      <div class="edu-image">
        <div class="teacher-card">
          <h3>{{ center.name }}</h3>
        </div>
      </div>
    </div>
    <div class="edu-card-body">
      <div class="detail">
        <img :src="`${publicPath}/media/images/center-detail/center.svg`" width="50" height="50" alt="">
        <span class="text-truncate"> {{ center.short_description }} </span>
      </div>
      <div class="detail">
        <img :src="`${publicPath}/media/images/center-detail/position-marker.svg`" width="50" height="50" alt="">
        <span>{{ this.center.address }}</span>
      </div>
      <div>
        <p class="detail">
          <img :src="`${publicPath}/media/images/center-detail/opening-times.svg`" width="50" height="50" alt="">
          <b> {{ this.center.created_at | formatDate }}</b>
        </p>
      </div>
      <el-rate
          :value="this.getRating(center.AVG)"
          disabled
          show-score
          text-color="#ff9900"
          :score-template="this.getTextStars(center.AVG)"
      >
      </el-rate>
    </div>
    <div class="edu-card-footer">
      <p class="students">
        <img :src="`${publicPath}/media/images/center-detail/user_amount.svg`" width="30" height="30" alt="">
        <b>Có {{ this.center.total_student }} học viên đăng ký</b>
      </p>
      <div>
        <router-link
            class="btn btn-info float-right view-detail"
            :to="{ path: '/detail-center/' + this.center.id, query:{
              customer_id : this.$route.query.customer_id,
              payment : this.$route.query.payment,
            } }"
            style="text-decoration: unset"
        >Xem chi tiết
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/tuvanvien.scss';

export default {
  name: "center-item",
  props: [
    'center',
    'is_sale_view'
  ],
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
    }
  },
  methods: {
    getRating(rating) {
      return parseInt(parseFloat(rating).toFixed(0));
    },
    getTextStars(start) {
      return '(' + parseInt(parseFloat(start).toFixed(0)) + '/5)';
    },
    getName(first_classroom) {
      if (!this.is_sale_view) {
        return first_classroom ? first_classroom.name : this.center.name;
      }
      return first_classroom ? first_classroom.name + ' - ' + this.center.branch_name : this.center.name + ' - ' + this.center.branch_name;
    },
  }
}
</script>

<style>
.teacher-card {
  background: #886ab5 !important;
}

.view-detail {
  background: #886ab5 !important;
  border: #886ab5 !important;
}

</style>